import React from 'react';
import { useAuthorization } from '../useAuthorization';
import { Navigate } from 'react-router-dom';

export const ProtectedRouteAdmin = ({ children }) => {
  if (useAuthorization.getUserRole() === 'admin' && useAuthorization.isTokenExpired() === false) {
    return children;
  } else {
    localStorage.clear();
    return <Navigate to="/admin" />;
  }
};
