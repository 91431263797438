import React from 'react';
import LayoutDedault from '../Common/LayoutDefault';
import { Button, Paper, Stack, TextField, Typography } from '@mui/material';
import { useLazyFetch } from '../Common/useFetch';
import { useNavigate } from 'react-router-dom';
import { API } from '../Common/constant';
import Swal from 'sweetalert2';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';

const AdminCreateClass = () => {
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      SchoolName: '',
      ClassName: '',
      KahootLink: '',
    },
    validationSchema: Yup.object({
      SchoolName: Yup.string().required('Không được để trống'),
      ClassName: Yup.string().required('Không được để trống'),
      KahootLink: Yup.string().required('Không được để trống'),
    }),
    validateOnChange: false,
    onSubmit: (values) => {
      Swal.fire({
        title: 'Bạn chắc chứ?',
        text: `Lớp ${formik.values.ClassName} sẽ được tạo`,
        icon: 'warning',
        showCancelButton: true,
        cancelButtonText: 'Hủy',
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Xác nhận',
      }).then((result) => {
        if (result.isConfirmed) {
          fetchData('', {
            body: {
              ClassName: values.ClassName,
              SchoolName: values.SchoolName,
              KahootLink: values.KahootLink,
            },
          });
        }
      });
    },
  });

  const [fetchData, { loading }] = useLazyFetch(`${API}/class/create`, {
    method: 'post',
    onCompleted: (data) => {
      navigate('/admin/dashboard');
    },
    onError: (error) => {
      Swal.fire({
        icon: 'error',
        text: error.message,
      });
    },
  });
  return (
    <LayoutDedault className="flex justify-center align-middle">
      <Paper
        className="flex flex-col justify-center align-middle items-center opacity-95"
        sx={{ borderRadius: '2rem', padding: '2rem', width: { xs: '90%', md: '50%' } }}
      >
        <PersonAddAlt1Icon color="primary" fontSize="large" />
        <Typography variant="h5" color="primary" className="mb-3">
          TẠO LỚP
        </Typography>
        <form onSubmit={formik.handleSubmit} className="w-full">
          <Stack direction="column" spacing={2} className="mb-3 mt-2 w-full">
            <TextField
              error={formik.errors.SchoolName && formik.touched.SchoolName}
              helperText={formik.errors.SchoolName}
              id="outlined-basic"
              label="Tên trường"
              variant="outlined"
              size="small"
              name="SchoolName"
              value={formik.values.SchoolName}
              onChange={formik.handleChange}
            />
            <TextField
              error={formik.errors.ClassName && formik.touched.ClassName}
              helperText={formik.errors.ClassName}
              id="outlined-basic"
              label="Tên lớp"
              variant="outlined"
              size="small"
              name="ClassName"
              value={formik.values.ClassName}
              onChange={formik.handleChange}
            />
            <TextField
              error={formik.errors.KahootLink && formik.touched.KahootLink}
              helperText={formik.errors.KahootLink}
              id="outlined-basic"
              label="Kahoot link"
              variant="outlined"
              size="small"
              name="KahootLink"
              value={formik.values.KahootLink}
              onChange={formik.handleChange}
            />

            <Button
              variant="contained"
              color="primary"
              sx={{
                borderRadius: { xs: '1rem', md: '.7rem' },
                fontSize: { xs: '1.3rem', md: '1.1rem' },
                paddingX: '2rem',
              }}
              type="submit"
            >
              Xác nhận
            </Button>
          </Stack>
        </form>
      </Paper>
    </LayoutDedault>
  );
};

export default AdminCreateClass;
