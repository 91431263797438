import React from 'react';
import { useNavigate } from 'react-router-dom';

const Logout = () => {
  const navigate = useNavigate();
  if (localStorage.getItem('accessToken')) {
    localStorage.clear();
    setTimeout(() => {
      navigate('/admin');
    }, 2000);
  }
  return <></>;
};

export default Logout;
