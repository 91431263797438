import React from 'react';
import LayoutDedault from '../Common/LayoutDefault';
import { FormHelperText, Paper, Stack, Typography } from '@mui/material';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import { useFormik } from 'formik';
import Swal from 'sweetalert2';
import { useLazyFetch, useFetch } from '../Common/useFetch';
import { API, MAJORS, CITIES } from '../Common/constant';
import * as Yup from 'yup';
import CircularProgress from '@mui/material/CircularProgress';
import Backdrop from '@mui/material/Backdrop';
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 200,
    },
  },
};

const CreateStudent = () => {
  const [major, setMajor] = React.useState([]);
  const [city, setCity] = React.useState('');
  const { data: classData, loading: classLoading, error } = useFetch(`${API}/class`);
  const handleChangeCity = (value) => {
    // Here is the value is a selected option label or the new typed value
    setCity(value);
    console.log(value);
  };
  const formik = useFormik({
    initialValues: {
      studentName: '',
      city_id: '',
      className: '',
      phoneNumber: '',
      major: [],
      advice: null,
    },
    validate: (values) => {
      if (major.length === 0) {
        return { major: 'Chọn ít nhất 1 ngành học' };
      }
      if (city === '') {
        return 'Chọn tỉnh/thành phố';
      }
    },
    validationSchema: Yup.object({
      studentName: Yup.string().required('Họ và tên không được để trống'),
      phoneNumber: Yup.string().required('Số điện thoại không được để trống'),
      // city_id: Yup.string().required('Tỉnh/Thành phố không được để trống'),
      className: Yup.string().required('Tên lớp không được để trống'),
      advice: Yup.boolean().required('Chọn một trong 2 lựa chọn'),
      // major: Yup.array().min(1, 'Chọn ít nhất 1 ngành học').of(Yup.string().required()).required(),
    }),
    validateOnChange: false,
    validateOnBlur: true,
    onSubmit: (values) => {
      Swal.fire({
        title: 'Bạn chắc chắn chứ?',
        text: 'Nhấn OK để xác nhận!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#445279',
        cancelButtonColor: '#e76565',
        confirmButtonText: 'OK',
      }).then((result) => {
        if (result.isConfirmed) {
          const idsArray = major.map((majorName) => {
            const majorObject = MAJORS.find((major) => major.majorName === majorName);
            return majorObject ? majorObject.majorId : null;
          });
          fetchData('', {
            body: {
              studentName: values.studentName,
              classId: values.className,
              city: city,
              phoneNumber: values.phoneNumber,
              majorIdList: idsArray,
              wantAdvice: values.advice,
            },
          });
        }
      });
    },
  });

  const [fetchData, { loading }] = useLazyFetch(`${API}/student`, {
    method: 'post',
    onCompleted: (data) => {
      Swal.fire({
        title: 'Xử lí thành công!',
        text: 'Nhấn OK để bắt đầu chơi game nhé!',
        icon: 'success',
        showCancelButton: true,
        confirmButtonColor: '#445279',
        cancelButtonColor: '#e76565',
        confirmButtonText: 'OK',
        allowOutsideClick: false,
      }).then((result) => {
        if (result.isConfirmed) {
          formik.resetForm();
          setMajor([]);
          setCity('');
          window.open(data.message, '_blank', 'noreferrer');
        }
      });
    },
    onError: (error) => {
      Swal.fire({
        icon: 'error',
        text: error.message,
      });
    },
  });

  const handleChange = (event, obj) => {
    const {
      target: { value },
    } = event;
    setMajor(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
  };

  if (classLoading)
    return (
      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 10 }} open>
        <CircularProgress />
      </Backdrop>
    );
  else
    return (
      <LayoutDedault className="flex justify-center align-middle">
        <Paper
          className="flex flex-col justify-center align-middle items-center opacity-95"
          sx={{ borderRadius: '2rem', padding: '2rem', width: { xs: '90%', md: '50%' } }}
        >
          <Typography variant="h5" fontFamily="Varela Round" color="#e5550f" fontWeight="bold">
            THÔNG TIN HỌC SINH
          </Typography>
          <form onSubmit={formik.handleSubmit} className="w-full">
            <Stack direction="column" spacing={2} className="mb-3 mt-2 w-full">
              <TextField
                error={formik.errors.studentName && formik.touched.studentName}
                helperText={formik.errors.studentName}
                id="outlined-basic"
                label="Họ và tên"
                variant="outlined"
                size="small"
                name="studentName"
                value={formik.values.studentName}
                onChange={formik.handleChange}
              />
              <TextField
                error={formik.errors.phoneNumber && formik.touched.phoneNumber}
                helperText={formik.errors.phoneNumber}
                id="outlined-basic"
                label="Số điện thoại"
                variant="outlined"
                size="small"
                name="phoneNumber"
                type="phone"
                value={formik.values.phoneNumber}
                onChange={formik.handleChange}
              />
              <Autocomplete
                id="city_id"
                name="city_id"
                size="small"
                options={CITIES}
                freeSolo
                onInputChange={(event, value) => handleChangeCity(value)}
                value={city}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={formik.errors.city_id && formik.touched.city_id}
                    helperText={formik.errors.city_id}
                    margin="normal"
                    label="Tỉnh/Thành phố"
                    fullWidth
                    name="city_id"
                  />
                )}
              />
              {/* {city.city_id === '' ? (
                <Typography fontStyle="italic" fontSize=".8rem">
                  Hãy chọn Tỉnh/Thành phố
                </Typography>
              ) : null} */}
              <FormControl>
                <FormLabel id="className">Tên lớp</FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="className"
                  name="className"
                  className="flex justify-center items-center"
                >
                  {classData?.length > 0 ? (
                    classData?.map((item, index) => {
                      return (
                        <FormControlLabel
                          key={item.id}
                          value={item.id}
                          control={<Radio />}
                          label={item.className}
                          className="flex justify-center items-center"
                          onChange={formik.handleChange}
                          checked={formik.values.className === item.id.toString()}
                        />
                      );
                    })
                  ) : (
                    <Typography
                      className="justify-start items-start"
                      fontStyle="italic"
                      fontSize=".8rem"
                    >
                      Hiện chưa có dữ liệu lớp :)
                    </Typography>
                  )}
                </RadioGroup>
                {formik.errors.className ? (
                  <FormHelperText error={true}>{formik.errors.className}</FormHelperText>
                ) : null}
              </FormControl>
              <FormControl sx={{ m: 1 }}>
                <InputLabel id="demo-multiple-checkbox-label">Ngành bạn muốn học</InputLabel>
                <Select
                  error={formik.errors.major && formik.touched.major}
                  helperText={formik.errors.major}
                  labelId="demo-multiple-checkbox-label"
                  id="demo-multiple-checkbox"
                  multiple
                  value={major}
                  onChange={handleChange}
                  name="major"
                  onBlur={formik.handleBlur}
                  input={<OutlinedInput label="Ngành mong muốn" />}
                  renderValue={(selected) => selected.join(', ')}
                  MenuProps={MenuProps}
                  className="mb-1"
                >
                  {MAJORS.map((option) => {
                    return (
                      <MenuItem key={option.majorId} value={option.majorName}>
                        <Checkbox checked={major.indexOf(option.majorName) > -1} />
                        <ListItemText primary={option.majorName} />
                      </MenuItem>
                    );
                  })}
                </Select>
                {major.length === 0 ? (
                  <Typography fontStyle="italic" fontSize=".8rem">
                    Hãy chọn ít nhất 1 ngành
                  </Typography>
                ) : null}
              </FormControl>
              <FormControl>
                <FormLabel id="advice">Bạn muốn nhận tư vấn từ Đại học FPT Cần Thơ?</FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="advice"
                  name="advice"
                  className="flex justify-center items-center"
                >
                  <FormControlLabel
                    value={true}
                    control={<Radio />}
                    label="Có"
                    className="flex justify-center items-center"
                    onChange={formik.handleChange}
                    // checked={formik.values.advice === true}
                  />
                  <FormControlLabel
                    value={false}
                    control={<Radio />}
                    label="Không"
                    className="flex justify-center items-center"
                    onChange={formik.handleChange}
                    // checked={formik.values.advice === false}
                  />
                </RadioGroup>
                {formik.errors.advice ? (
                  <FormHelperText error={true}>{formik.errors.advice}</FormHelperText>
                ) : null}
              </FormControl>
              <Button
                variant="contained"
                color="primary"
                sx={{
                  borderRadius: { xs: '1rem', md: '.7rem' },
                  fontSize: { xs: '1.3rem', md: '1.1rem' },
                  paddingX: '2rem',
                }}
                type="submit"
              >
                Xác Nhận
              </Button>
            </Stack>
          </form>
        </Paper>
      </LayoutDedault>
    );
};

export default CreateStudent;
