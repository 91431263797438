import { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

axios.defaults.withCredentials = true;

axios.interceptors.request.use(
  function (config) {
    var token = localStorage.getItem('accessToken');
    config.headers.Authorization = token ? `Bearer ${token}` : '';
    return config;
  },
  function (error) {
    return Promise.reject(error);
  },
);

export const useFetch = (url, options = {}) => {
  const [data, setData] = useState(undefined);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(undefined);
  let history = useNavigate();

  useEffect(() => {
    if (!url) return;
    handleFetch(url, options, setData, setLoading, setError, history);
  }, []);

  return { data, loading, error };
};

export const useLazyFetch = (url, options = {}) => {
  const [data, setData] = useState(undefined);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(undefined);

  let history = useNavigate();

  async function fetchData(urlParam = '', optionsParam = {}) {
    setLoading(true);

    // If passing new url and options => get the later
    const newOptions = {
      ...options,
      ...optionsParam,
    };

    const newUrl = urlParam || url;

    handleFetch(newUrl, newOptions, setData, setError, setLoading, history);
  }

  return [fetchData, { loading, data, error }];
};

async function handleFetch(url, options = {}, setData, setLoading, setError, history) {
  try {
    // Try to fetch data
    const response = await axios(url, { ...options, data: options.body });

    setData(response.data);

    options.onCompleted && options.onCompleted(response.data);
    setError(undefined);
    setLoading(false);

    // Finally
    options.finally && options.finally(response.data);
  } catch (error) {
    if (error.response) {
      if (error.response.data.status === 401) {
        localStorage.clear();
        history('/admin');
      }
      setLoading(false);
      setError(error.response.data);
      setData(undefined);
      options.onError && options.onError(error.response.data);
    }
  }
}
