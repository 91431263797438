export const API = process.env.REACT_APP_SOKKER_API;
export const MAJORS = [
  { majorName: 'Kỹ thuật phần mềm', majorId: 1 },
  { majorName: 'Trí tuệ nhân tạo', majorId: 2 },
  { majorName: 'An toàn & bảo mật thông tin', majorId: 3 },
  { majorName: 'Công nghệ ô tô số', majorId: 4 },
  { majorName: 'Hệ thống thông tin', majorId: 5 },
  { majorName: 'Thiết kế mỹ thuật số', majorId: 6 },
  { majorName: 'Ngôn ngữ Anh', majorId: 7 },
  { majorName: 'Ngôn ngữ Trung', majorId: 8 },
  { majorName: 'Ngôn ngữ Nhật', majorId: 9 },
  { majorName: 'Ngôn ngữ Hàn', majorId: 10 },
  { majorName: 'Quản trị kinh doanh (Digital marketing)', majorId: 11 },
  { majorName: 'Kinh doanh quốc tế', majorId: 12 },
  { majorName: 'Quản trị khách sạn', majorId: 13 },
  { majorName: 'Tài chính', majorId: 14 },
  { majorName: 'Quản trị dịch vụ du lịch & lữ hành', majorId: 15 },
  { majorName: 'Logistics & quản trị chuỗi cung ứng', majorId: 16 },
  { majorName: 'Truyền thông đa phương tiện', majorId: 17 },
  { majorName: 'Quan hệ công chúng', majorId: 18 },
];
export const CITIES = [
  'Cần Thơ',
  'Vĩnh Long',
  'An Giang',
  'Sóc Trăng',
  'Cà Mau',
  'Đồng Tháp',
  'Kiên Giang',
  'Hậu Giang',
  'Bạc Liêu',
  'Trà Vinh',
];
