import React, { useState } from 'react';
import { API } from '../Common/constant';
import axios from 'axios';
import { Button } from '@mui/material';
import Swal from 'sweetalert2';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import { useNavigate } from 'react-router-dom';

const ExportExcelButton = (className) => {
  var startDate = new Date();
  var endDate = new Date(startDate);
  endDate.setDate(startDate.getDate() + 1);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const handleDownload = async () => {
    Swal.fire({
      title: 'Bạn chắc chứ?',
      text: 'Tập tin sẽ được tải xuống thiết bị của bạn.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Chấp nhận',
      cancelButtonText: 'Hủy',
    }).then(async (result) => {
      if (result.isConfirmed) {
        setLoading(true);
        //useLazyFetch is not working correctly with blob type
        try {
          const response = await axios.get(
            `${API}/Student/excel?startDate=${startDate.toISOString()}&endDate=${endDate.toISOString()}`,
            { responseType: 'blob' },
          );
          setLoading(false);
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          const contentDisposition = response.headers['content-disposition'];
          console.log(contentDisposition)
          let filename = 'Report.xlsx'; // Default filename
          if (contentDisposition) {
            const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
            let matches = filenameRegex.exec(contentDisposition);
            if (matches != null && matches[1]) {
              filename = matches[1].replace(/['"]/g, '');
            }
          }
          link.setAttribute('download', filename);
          document.body.appendChild(link);
          link.click();
        } catch (error) {
          if (error.response) {
            if (error.response.status === 401) {
              Swal.fire({
                icon: 'error',
                text: 'Phiên đăng nhập đã hết hạn, vui lòng đăng nhập lại',
                confirmButtonText: 'Đăng nhập lại',
              }).then((result) => {
                /* Read more about isConfirmed, isDenied below */
                if (result.isConfirmed) {
                  localStorage.clear();
                  navigate('/admin');
                }
              });
            }
          }
        }
      }
    });
  };

  return (
    <div className={className}>
      {loading ? (
        <Button
          disabled
          startIcon={<CloudDownloadIcon />}
          variant="contained"
          color="secondary"
          sx={{
            borderRadius: { xs: '.5rem', md: '.7rem' },
            fontSize: { xs: '1.2rem', md: '1.1rem' },
          }}
        >
          <div className="text-transform: capitalize leading-none text-right">Đang Chuẩn bị</div>
        </Button>
      ) : (
        <Button
          startIcon={<CloudDownloadIcon />}
          variant="contained"
          color="secondary"
          sx={{
            borderRadius: { xs: '.5rem', md: '.7rem' },
            fontSize: { xs: '1.2rem', md: '1.1rem' },
          }}
          onClick={() => handleDownload()}
        >
          Xuất File Excel
        </Button>
      )}
    </div>
  );
};

export default ExportExcelButton;
