import './App.css';
import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import AdminDashboard from './Page/AdminDashboard';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import AdminLogin from './Page/AdminLogin';
import AdminCreateClass from './Page/AdminCreateClass';
import { ProtectedRouteAdmin } from './Common/Routes/ProtectedRouteAdmin';
import { useAuthorization } from './Common/useAuthorization';
import { useEffect } from 'react';
import CreateStudent from './Page/CreateStudent';
import Logout from './Page/Logout';

function App() {
  const theme = createTheme({
    typography: {
      fontFamily: 'Varela Round',
      fontWeightRegular: 550,
      fontWeightBold: 900,
    },
    palette: {
      primary: {
        main: '#e5550f',
      },
      secondary: {
        main: '#1D6F42',
      },
    },
  });

  useEffect(() => {
    document.title = 'Cóc Non';
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <Routes>
        <Route path="*" element={<CreateStudent />} />
        <Route path="/" element={<CreateStudent />} />
        <Route path="/admin/logout" element={<Logout />} />
        {/* if user is logged in, won't show the login form again but redirect to the dashboard */}
        <Route
          path="/admin"
          element={
            useAuthorization.getUserRole() === 'admin' &&
            useAuthorization.isTokenExpired() === false ? (
              <Navigate to="/admin/dashboard" replace />
            ) : (
              <AdminLogin />
            )
          }
        />

        <Route
          path="/admin/create"
          element={
            <ProtectedRouteAdmin>
              <AdminCreateClass />
            </ProtectedRouteAdmin>
          }
        />

        <Route
          path="/admin/dashboard"
          element={
            <ProtectedRouteAdmin>
              <AdminDashboard />
            </ProtectedRouteAdmin>
          }
        />
      </Routes>
    </ThemeProvider>
  );
}

export default App;
