import React from 'react';
import NavBarDefault from './NavBarDefault';
import { Box } from '@mui/material';

const LayoutDedault = ({ className, children }) => {
  return (
    <div>
      <NavBarDefault />
      <Box className={className}>{children}</Box>
    </div>
  );
};

export default LayoutDedault;
