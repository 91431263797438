import React from 'react';
import LayoutDedault from '../Common/LayoutDefault';
import { Button, Paper } from '@mui/material';
import { Typography } from '@mui/material';
import { Stack } from '@mui/material';
import { TextField } from '@mui/material';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import { useLazyFetch } from '../Common/useFetch';
import Swal from 'sweetalert2';
import { API } from '../Common/constant';
import { useNavigate } from 'react-router-dom';

const AdminLogin = () => {
  const navigate = useNavigate();

  function setLocalstorage(data) {
    localStorage.setItem('accessToken', data.accessToken);
    localStorage.setItem('userName', data.username);
  }

  const formik = useFormik({
    initialValues: {
      userName: '',
      passWord: '',
    },
    validationSchema: Yup.object({
      userName: Yup.string().required('Không được để trống'),
      passWord: Yup.string().required('Không được để trống'),
    }),
    validateOnChange: false,
    validateOnBlur: true,
    onSubmit: (values) => {
      fetchData('', {
        body: {
          username: values.userName,
          password: values.passWord,
        },
      });
    },
  });

  const [fetchData, { loading }] = useLazyFetch(`${API}/auth/login`, {
    method: 'post',
    onCompleted: (data) => {
      setLocalstorage(data);
      navigate('/admin/dashboard');
    },
    onError: (error) => {
      Swal.fire({
        icon: 'error',
        text: error.message,
      });
    },
  });
  return (
    <LayoutDedault className="flex justify-center align-middle">
      <Paper
        className="flex flex-col justify-center align-middle items-center opacity-95"
        sx={{ borderRadius: '2rem', padding: '2rem', width: { xs: '90%', md: '50%' } }}
      >
        <AssignmentIndIcon color="primary" fontSize="large" />
        <Typography variant="h5" color="primary" className="mb-3">
          Đăng nhập Admin
        </Typography>
        <form onSubmit={formik.handleSubmit} className="w-full">
          <Stack direction="column" spacing={2} className="mb-3 mt-2 w-full">
            <TextField
              error={formik.errors.userName && formik.touched.userName}
              helperText={formik.errors.userName}
              id="outlined-basic"
              label="Tài khoản"
              variant="outlined"
              size="small"
              name="userName"
              value={formik.values.userName}
              onChange={formik.handleChange}
            />
            <TextField
              error={formik.errors.passWord && formik.touched.passWord}
              helperText={formik.errors.passWord}
              id="outlined-basic"
              label="Mật Khẩu"
              variant="outlined"
              size="small"
              name="passWord"
              type="password"
              value={formik.values.passWord}
              onChange={formik.handleChange}
            />
            <Button
              variant="contained"
              color="primary"
              sx={{
                borderRadius: { xs: '1rem', md: '.7rem' },
                fontSize: { xs: '1.3rem', md: '1.1rem' },
                paddingX: '2rem',
              }}
              type="submit"
            >
              Đăng nhập
            </Button>
          </Stack>
        </form>
      </Paper>
    </LayoutDedault>
  );
};

export default AdminLogin;
