import { jwtDecode } from 'jwt-decode';

const setToken = (token) => {
  localStorage.setItem('accessToken', token);
};

const getToken = () => {
  const token = localStorage.getItem('accessToken');
  if (token) {
    return token;
  }
  return null;
};

const getUserEmail = () => {
  const token = getToken();
  if (token) {
    const payLoad = jwtDecode(token);
    return payLoad?.email;
  }
  return null;
};

const getUserRole = () => {
  const token = getToken();
  if (token) {
    const payLoad = jwtDecode(token);
    return payLoad?.role;
  }
  return null;
};

const isTokenExpired = () => {
  const token = getToken();
  if (token) {
    const payLoad = jwtDecode(token);
    // If the current time is greater than the expiration time, the token is expired
    const isExpired = Date.now() >= payLoad.exp * 1000;
    return isExpired;
  }
  // If there's no token, we can consider it as expired
  return true;
};

const logOut = () => {
  localStorage.clear();
};

export const useAuthorization = {
  logOut,
  getToken,
  setToken,
  getUserEmail,
  getUserRole,
  isTokenExpired,
};
