import React, { useState } from 'react';
import { API } from '../Common/constant';
import LayoutDedault from '../Common/LayoutDefault';
import { DataGrid } from '@mui/x-data-grid';
import { CircularProgress, Paper, Stack, Typography } from '@mui/material';
import ExportExcelButton from './ExportExcelButton';
import { useFetch, useLazyFetch } from '../Common/useFetch';
import Loading from '../Common/Loading';
import { Button } from '@mui/material';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';

const AdminDashboard = () => {
  const columns = [
    { field: 'className', headerName: 'Lớp', width: 120 },
    { field: 'schoolName', headerName: 'Trường', width: 350 },
    { field: 'kahootLink', headerName: 'Link Kahoot', width: 500 },
    { field: 'createdAt', headerName: 'Thời gian tạo', width: 400 },
  ];
  const { data, loading: classLoading, error } = useFetch(`${API}/Class`);

  const navigate = useNavigate();
  const [classIds, setClassIds] = useState([]);
  const [fetchData, { loading }] = useLazyFetch(`${API}/Class/delete`, {
    method: 'post',
    onCompleted: (data) => {
      navigate(0);
    },
    onError: (error) => {
      Swal.fire({
        icon: 'error',
        text: error.message,
      });
    },
  });
  const getClassNamesToString = (classIds, classes) => {
    const classNames = classIds.map((classId) => {
      const foundClass = classes.find((cls) => cls.id === classId);
      return foundClass ? foundClass.className : null;
    });
    return classNames.filter(Boolean).join(', '); // Filter out null values and join with comma
  };

  const confirmAndDeleteClass = () => {
    const classList = getClassNamesToString(classIds, data);
    Swal.fire({
      title: 'Bạn chắc chắn chứ?',
      text: `Lớp ${classList} sẽ bị xóa`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#445279',
      cancelButtonColor: '#e76565',
      confirmButtonText: 'OK',
    }).then((result) => {
      if (result.isConfirmed) {
        fetchData('', {
          body: classIds,
        });
      }
    });
  };
  if (classLoading) return <Loading />;

  return (
    <LayoutDedault className="flex justify-center align-middle">
      <Paper
        className="flex flex-col justify-center align-middle items-center"
        sx={{ borderRadius: '2rem', padding: '2rem', width: '90%' }}
      >
        <Stack
          direction={{ lg: 'row', xs: 'column' }}
          spacing={2}
          className="flex xs:justify-center lg:justify-between align-middle items-center w-full mb-5"
        >
          <Typography variant="h5" fontWeight="bold" color="primary">
            DANH SÁCH CÁC LỚP
          </Typography>
          <Stack direction="row" spacing={2}>
            <Button
              startIcon={<AddCircleIcon />}
              variant="contained"
              color="primary"
              sx={{
                borderRadius: { xs: '.5rem', md: '.7rem' },
                fontSize: { xs: '.9rem', md: '1.1rem' },
              }}
              onClick={() => navigate('/admin/create')}
            >
              Thêm Lớp
            </Button>
            <Button
              startIcon={<DeleteIcon />}
              variant="contained"
              color="error"
              sx={{
                borderRadius: { xs: '.5rem', md: '.7rem' },
                fontSize: { xs: '.9rem', md: '1.1rem' },
              }}
              onClick={confirmAndDeleteClass}
              disabled={classIds.length === 0}
            >
              Xóa Lớp
            </Button>
          </Stack>
        </Stack>
        <div style={{ height: 400, width: '100%', marginBottom: '1rem' }}>
          <DataGrid
            rows={data ? data : []}
            getRowId={(row) => row.id}
            columns={columns}
            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 5 },
              },
            }}
            pageSizeOptions={[5, 10]}
            checkboxSelection
            onRowSelectionModelChange={(rows) => {
              setClassIds(rows);
            }}
          />
        </div>
        <ExportExcelButton />
      </Paper>
    </LayoutDedault>
  );
};

export default AdminDashboard;
