import React from 'react'
import LayoutDedault from './LayoutDefault'
import { CircularProgress } from '@mui/material';
function Loading() {
  return (
    <LayoutDedault className="flex justify-center align-middle">
      <CircularProgress color='info' className='m-20' size={100} />
    </LayoutDedault>
    )
}

export default Loading